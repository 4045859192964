<template>
  <div>
    <div class="text-center mt-4">
      <h4>
        EVALUACIONES:
        <span v-if="matter">{{ matter.code }} - {{ matter.name }}</span>
      </h4>
    </div>
    <div class="d-flex">
      <GenericButtonDownload
        v-if="egressProfilesMatter"
        :tooltip_text="`EVALUACIÓN XLSX`"
        :click_button="
          () => LearningSuitcasePageTwo(egressProfilesMatter.matter)
        "
        :class="`ml-auto mb-2 mr-3`"
        :icon_excel="true"
      ></GenericButtonDownload>
      <!-- <b-button
        v-if="egressProfilesMatter"
        class="learning-suitcase-btn"
        size="sm"
        @click="LearningSuitcasePageTwo(egressProfilesMatter.matter)"
        ><b-icon-download class="mr-1 icon-download"></b-icon-download
        >EVALUACIÓN XLS</b-button
      > -->
    </div>
    <div class="learning-experiences-container">
      <b-table-simple
        bordered
        no-border-collapse
        small
        fixed
        responsive
        sticky-header="1000px"
        class="txt-custom"
      >
        <b-thead>
          <b-tr>
            <b-th class="text-center primary-header" colspan="13"
              >Evaluaciones</b-th
            >
          </b-tr>
          <b-tr>
            <b-th class="text-center align-middle secondary-header"
              >Tipo de evaluación</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Resultado de aprendizaje</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Indicadores de Logro</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Situación evaluativa</b-th
            >
            <b-th
              class="text-center align-middle secondary-header"
              v-b-tooltip.v-secondary.noninteractive="
                'Descripción evaluación (Mencionar las características principales que configuran la actividad de evaluación: características principales del encargo, ejecución práctica, pruebas escritas o presentación)'
              "
              >Descripción evaluación
            </b-th>
            <b-th class="text-center align-middle secondary-header"
              >Agente evaluativo</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Ponderación evaluación</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Ponderación por situación evaluativa</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Horas de Docencia Dirigida (pedagógicas)</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Horas de Trabajo Autónomo Preparación y/o Ejecución
              (cronológicas)</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Tipo de trabajo</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Ambiente</b-th
            >
            <b-th class="text-center align-middle secondary-header"
              >Examinador/a</b-th
            >
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-if="learning_evaluation.length > 0">
            <template v-for="matter_evaluation in learning_evaluation">
              <b-tr
                v-for="(evaluation_test, index) in matter_evaluation.tests"
                :key="
                  index + 'index_test' + matter_evaluation.id + 'id_evaluation'
                "
              >
                <!-- Tipo de evaluación -->
                <b-td
                  v-if="index == 0"
                  class="text-left"
                  :rowspan="matter_evaluation.tests.length"
                >
                  {{ matter_evaluation.evaluation_type }}
                </b-td>
                <!-- Resultado de aprendizaje -->
                <b-td
                  v-if="index == 0"
                  :rowspan="matter_evaluation.tests.length"
                >
                  {{ matter_evaluation.learning_results }}
                </b-td>
                <!-- Indicadores de Logro -->
                <b-td
                  v-if="index == 0"
                  :rowspan="matter_evaluation.tests.length"
                >
                  {{ matter_evaluation.evaluation_criterias_micro }}
                </b-td>
                <!-- Situación evaluativa -->
                <b-td class="text-left">
                  {{ evaluation_test.evaluation_situation }}
                </b-td>
                <!-- Descripción evaluación -->
                <b-td
                  v-if="index == 0"
                  :rowspan="matter_evaluation.tests.length"
                >
                  <div
                    class="rich-text-content description-evaluation"
                    v-html="matter_evaluation.description"
                  ></div>
                </b-td>
                <!-- Agente evaluativo -->
                <b-td class="text-left">
                  {{ evaluation_test.evaluative_agent }}
                </b-td>
                <!-- Ponderación evaluación -->
                <b-td
                  v-if="index == 0"
                  :rowspan="matter_evaluation.tests.length"
                >
                  {{ matter_evaluation.weighing }}%</b-td
                >
                <!-- Ponderación por Situacion Evaluativa -->
                <b-td> {{ evaluation_test.weighing }}% </b-td>
                <!-- Horas de Docencia Dirigida (pedagógicas) -->
                <b-td
                  v-if="index == 0"
                  :rowspan="matter_evaluation.tests.length"
                  class="text-left"
                >
                  <div
                    v-for="(
                      pedagogical_hour, index
                    ) in matter_evaluation.pedagogical_hours.filter(
                      (x) => x.hours > 0
                    )"
                    :key="`pedagogical_hour-${index}`"
                  >
                    <p>
                      {{ pedagogical_hour.hours }} Hrs.
                      {{
                        pedagogical_hour.study_environment != null
                          ? pedagogical_hour.study_environment
                          : `Sin ${$getVisibleNames(
                              "mesh.studyenvironment",
                              false,
                              "Ambiente de Aprendizaje"
                            )}`
                      }}
                    </p>
                  </div>
                </b-td>
                <!-- Horas de Trabajo Autónomo Preparación y/o Ejecución
              (cronológicas)  -->
                <b-td
                  v-if="index == 0"
                  :rowspan="matter_evaluation.tests.length"
                  class="text-left"
                >
                  <div
                    v-for="(
                      chronological_hour, index
                    ) in matter_evaluation.chronological_hours.filter(
                      (x) => x.hours > 0
                    )"
                    :key="`chronological_hour-${index}`"
                  >
                    <p>
                      {{ chronological_hour.hours }} Hrs.
                      {{
                        chronological_hour.study_environment != null
                          ? chronological_hour.study_environment
                          : `Sin ${$getVisibleNames(
                              "mesh.studyenvironment",
                              false,
                              "Ambiente de Aprendizaje"
                            )}`
                      }}
                    </p>
                  </div>
                </b-td>
                <!-- Tipo de trabajo -->
                <b-td>
                  {{ evaluation_test.work_type }}
                </b-td>
                <!-- Ambiente -->
                <b-td
                  v-if="index == 0"
                  :rowspan="matter_evaluation.tests.length"
                >
                  {{ matter_evaluation.study_environments }}
                </b-td>
                <!-- Examinador/a -->
                <b-td>
                  {{ evaluation_test.examiner }}
                </b-td>
              </b-tr>
              <!-- Template cuando no existen test -->
              <template v-if="matter_evaluation.tests.length == 0">
                <b-tr :key="matter_evaluation.id + 'id_evaluation'">
                  <!-- Tipo de evaluación -->
                  <b-td class="text-left">
                    {{ matter_evaluation.evaluation_type }}
                  </b-td>
                  <!-- Resultado de aprendizaje -->
                  <b-td>
                    {{ matter_evaluation.learning_results }}
                  </b-td>
                  <!-- Indicadores de Logro -->
                  <b-td>
                    {{ matter_evaluation.evaluation_criterias_micro }}
                  </b-td>
                  <!-- Situación evaluativa -->
                  <b-td class="text-left"> </b-td>
                  <!-- Descripción evaluación -->
                  <b-td>
                    <div
                      class="rich-text-content description-evaluation"
                      v-html="matter_evaluation.description"
                    ></div>
                  </b-td>
                  <!-- Agente evaluativo -->
                  <b-td class="text-left"> </b-td>
                  <!-- Ponderación evaluación -->
                  <b-td> {{ matter_evaluation.weighing }}%</b-td>
                  <!-- Ponderación por Situacion Evaluativa -->
                  <b-td></b-td>
                  <!-- Horas de Docencia Dirigida (pedagógicas) -->
                  <b-td class="text-left">
                    <div
                      v-for="(
                        pedagogical_hour, index
                      ) in matter_evaluation.pedagogical_hours.filter(
                        (x) => x.hours > 0
                      )"
                      :key="`pedagogical_hour-${index}`"
                    >
                      <p>
                        {{ pedagogical_hour.hours }} Hrs.
                        {{
                          pedagogical_hour.study_environment != null
                            ? pedagogical_hour.study_environment
                            : `Sin ${$getVisibleNames(
                                "mesh.studyenvironment",
                                false,
                                "Ambiente de Aprendizaje"
                              )}`
                        }}
                      </p>
                    </div>
                  </b-td>
                  <!-- Horas de Trabajo Autónomo Preparación y/o Ejecución
              (cronológicas)  -->
                  <b-td class="text-left">
                    <div
                      v-for="(
                        chronological_hour, index
                      ) in matter_evaluation.chronological_hours.filter(
                        (x) => x.hours > 0
                      )"
                      :key="`chronological_hour-${index}`"
                    >
                      <p>
                        {{ chronological_hour.hours }} Hrs.
                        {{
                          chronological_hour.study_environment != null
                            ? chronological_hour.study_environment
                            : `Sin ${$getVisibleNames(
                                "mesh.studyenvironment",
                                false,
                                "Ambiente de Aprendizaje"
                              )}`
                        }}
                      </p>
                    </div>
                  </b-td>
                  <!-- Tipo de trabajo -->
                  <b-td> </b-td>
                  <!-- Ambiente -->
                  <b-td>
                    {{ matter_evaluation.study_environments }}
                  </b-td>
                  <!-- Examinador/a -->
                  <b-td> </b-td>
                </b-tr>
              </template>
            </template>
          </template>
          <!-- Template cuando no hay evaluaciones -->
          <template v-else-if="learning_evaluation.length == 0">
            <b-tr>
              <b-td colspan="13">
                <strong> No hay datos para mostrar </strong>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "LearningEvaluationView",
  components: {
    GenericButtonDownload: () =>
      import("@/components/reusable/Buttons/GenericButtonDownload"),
  },
  props: {},
  data() {
    return {
      egress_profile_matter_id: Number(
        this.$route.params.egress_profile_matter_id
      ),
      learning_evaluation: [],
    };
  },
  computed: {
    ...mapGetters({
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
    }),
    matter() {
      if (!this.egressProfilesMatter) return null;
      return this.matters.find((x) => x.id == this.egressProfilesMatter.matter);
    },
    egressProfilesMatter() {
      if (!this.egress_profile_matter_id) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
  },
  methods: {
    LearningSuitcasePageTwo(matter_id) {
      if (matter_id)
        this.$restful
          .Get(
            `/teaching/learning_suitcase_page_two_excel/?egress_profile_matter=${this.egress_profile_matter_id}`
          )
          .then((response) => {
            if (response && response.file_url)
              window.open(response.file_url, "_blank");
          });
    },
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
  },
  created() {
    this.$store
      .dispatch(
        names.FETCH_EGRESS_PROFILE_MATTER,
        this.egress_profile_matter_id
      )
      .then((response) => {
        if (response && response.matter) {
          this.$restful
            .Get(
              `/teaching/learning_suitcase_page_two_web/?egress_profile_matter=${this.egress_profile_matter_id}`
            )
            .then((learning_evaluation) => {
              this.learning_evaluation = learning_evaluation;
            });
          this.$store
            .dispatch(names.FETCH_MATTER, response.matter)
            .then((matter) => {
              this.$store.commit(
                names.MUTATE_TITLE_OF_THE_PRINT,
                `Evaluaciones - ${matter.code} ${matter.name}`
              );
            });
        }
      });
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.description-evaluation {
  max-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
}
.learning-suitcase-btn {
  margin-left: auto;
  margin-right: 0;
}
.txt-custom {
  font-size: 9pt;
}
.primary-header {
  background: black !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 26px;
}
.secondary-header {
  background: #d0cece !important;
}
@media print {
  .badge {
    border: none !important;
  }
  .learning-experiences-container {
    zoom: 52%;
    display: table;
    table-layout: inherit;
  }
}
</style>